import React, { forwardRef, Ref } from 'react';
import styled from 'styled-components';

import Error from './Error';

const Wrapper = styled.div<{ $fluid?: boolean; $block?: boolean }>`
  display: ${({ $block }) => ($block ? 'block' : 'inline-block')};
  width: ${({ $fluid }) => ($fluid ? '100%' : 'auto')};
`;

const Input = styled.input<{
  $border?: string;
  $error?: boolean;
  $fluid?: boolean;
  $block?: boolean;
}>`
  box-sizing: border-box;
  height: ${({ theme }) => theme.sizes.formControlHeight};
  padding: ${({ theme }) => theme.sizes.lvl3} ${({ theme }) => theme.sizes.lvl3};

  background: white;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  border: ${({ $border }) => $border};
  outline: ${({ theme, $error }) =>
    $error ? `${theme.colours.signal.error} 3px solid` : 'none'};
  width: ${({ $fluid, $block }) => ($fluid && !$block ? '100%' : 'auto')};

  ::placeholder {
    color: ${({ theme }) => theme.colours.textDisabled};
  }
  :hover,
  :active,
  :focus {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  }
  :focus {
    outline: ${({ theme, $error }) =>
      $error ? `${theme.colours.signal.error} 3px solid` : 'none'};
  }

  font-size: ${({ theme }) => theme.font.sizes.lg};
`;

interface TextInputProps {
  className?: string;
  id?: string;
  ref?: Ref<HTMLInputElement>;
  fluid?: boolean;
  block?: boolean;
  error?: string;
  border?: string;
  [x: string]: any;
}

const TextInput = forwardRef(
  (
    {
      fluid,
      block,
      error,
      border = 'none',
      className,
      ...otherProps
    }: TextInputProps,
    ref
  ) => {
    return (
      <Wrapper $block={block} $fluid={fluid} className={className}>
        <Input
          $fluid={fluid}
          $error={!!error}
          $border={border}
          ref={ref}
          {...otherProps}
        />
        <Error error={error} />
      </Wrapper>
    );
  }
);

export default TextInput;
